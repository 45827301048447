/**
 * Roll up data used to return content
 * if input locale data does not exist in translation files
 */
 // eslint-disable-next-line @typescript-eslint/no-extraneous-class
 export class BookingWidgetTranslations {
    /**
     * @ignore
     */
    public static DATA: any = {
      'en_US': {
        'Flights': 'Flights',
        'Vacations': 'Vacations',
        'RoundTrip': 'Round trip',
        'OneWay': 'One way',
        'OnePassenger': '1 passenger',
        'TwoPassenger': '2 passengers',
        'ThreePassenger': '3 passengers',
        'FourPassenger': '4 passengers',
        'FivePassenger': '5 passengers',
        'SixPassenger': '6 passengers',
        'SevenPassenger': '7 passengers',
        'EightPassenger': '8 passengers',
        'NinePassenger': '9 passengers',
        'From': 'From',
        'To': 'To',
        'LeavingFrom': 'Leaving from',
        'GoingTo': 'Going to',
        'Depart': 'Depart',
        'Return': 'Return',
        'RedeemMiles': 'Redeem miles',
        'AdvancedMultiCitySearch': 'Advanced / Multi-city search',
        'Search': 'Search',
        'SubmitSearch': ', Submit search',
        'SearchHotels': ', Search hotels',
        'SearchCars': ', Search cars',
        'FromCurrentLocationPlaceholder': 'Use current location for departure airport or city',
        'ToCurrentLocationPlaceholder': 'Use current location for destination airport or city',
        'TravelType': 'Travel type',
        'SelectTravelType': 'Select travel type',
        'Personal': 'Personal',
        'Business':'Business',
        'AirPass': 'AirPass',
        'Company': 'Company',
        'SelectCompany': 'Select company',
        'BusinessTraveler': 'Business traveler',
        'SelectBusinessTraveler': 'Select business traveler',
        'LoadingText': 'Loading...',
        'Guest': 'GUEST',
        'SearchTravelerName': 'Search traveler name',
        'mileRedemption': 'You haven\'t unlocked your mile redemption benefits yet.',
        'travelManagementPortal': 'Travel management portal'
      },
      'es': {
        'Flights': 'Vuelo',
        'Vacations': 'Paquetes vacacionales',
        'RoundTrip': 'Ida y vuelta',
        'OneWay': 'Sólo ida',
        'OnePassenger': '1 pasajero',
        'TwoPassenger': '2 pasajeros',
        'ThreePassenger': '3 pasajeros',
        'FourPassenger': '4 pasajeros',
        'FivePassenger': '5 pasajeros',
        'SixPassenger': '6 pasajeros',
        'SevenPassenger': '7 pasajeros',
        'EightPassenger': '8 pasajeros',
        'NinePassenger': '9 pasajeros',
        'From': 'Salir de',
        'To': 'Llegar a',
        'LeavingFrom': 'Saliendo de',
        'GoingTo': 'Yendo a',
        'Depart': 'Fecha de salida',
        'Return': 'Fecha de retorno',
        'RedeemMiles': 'Canjear millas',
        'AdvancedMultiCitySearch': 'Búsqueda avanzada/múltiples ciudades',
        'Search': 'Buscar vuelos',
        'SubmitSearch': ', Enviar búsqueda',
        'SearchHotels': ', Buscar hoteles',
        'SearchCars': ', Buscar coches',
        'FromCurrentLocationPlaceholder': 'Use la ubicación actual para el aeropuerto o ciudad de salida',
        'ToCurrentLocationPlaceholder': 'Use la ubicación actual para el aeropuerto o ciudad de destino',
        'TravelType': 'Tipo de viaje',
        'SelectTravelType': 'Seleccionar el tipo de viaje',
        'Personal': 'Personal',
        'Business':'De negocios',
        'AirPass': 'AirPass',
        'Company': 'Compañía',
        'SelectCompany': 'Seleccionar compañía',
        'BusinessTraveler': 'Viajero de negocios',
        'SelectBusinessTraveler': 'Seleccionar al viajero de negocios',
        'LoadingText': 'Cargando...',
        'Guest': 'INVITADO',
        'SearchTravelerName': 'Buscar nombre del viajero',
        'mileRedemption': 'Aún no ha activado sus beneficios de canje de millas.',
        'travelManagementPortal': 'Portal de gestión de viajes'
      },
      'fr': {
        'Flights': 'Vol',
        'Vacations': 'Forfaits vacances',
        'RoundTrip': 'Aller-retour',
        'OneWay': 'Aller simple',
        'OnePassenger': '1 passager',
        'TwoPassenger': '2 passagers',
        'ThreePassenger': '3 passagers',
        'FourPassenger': '4 passagers',
        'FivePassenger': '5 passagers',
        'SixPassenger': '6 passagers',
        'SevenPassenger': '7 passagers',
        'EightPassenger': '8 passagers',
        'NinePassenger': '9 passagers',
        'From': 'De',
        'To': 'Vers',
        'LeavingFrom': 'Départ de',
        'GoingTo': 'Vers',
        'Depart': 'Départ',
        'Return': 'Retour',
        'RedeemMiles': 'Echanger des miles',
        'AdvancedMultiCitySearch': 'Recherche avancée ou multi-destinations',
        'Search': 'Rechercher',
        'FromCurrentLocationPlaceholder': 'Utiliser l’emplacement actuel pour l’aéroport ou la ville de départ',
        'ToCurrentLocationPlaceholder': 'Utiliser l’emplacement actuel pour l’aéroport ou la ville de destination'
      },
      'fr_CA': {
        'Flights': 'Vol',
        'Vacations': 'Forfaits vacances',
        'RoundTrip': 'Aller-retour',
        'OneWay': 'Aller simple',
        'OnePassenger': '1 passager',
        'TwoPassenger': '2 passagers',
        'ThreePassenger': '3 passagers',
        'FourPassenger': '4 passagers',
        'FivePassenger': '5 passagers',
        'SixPassenger': '6 passagers',
        'SevenPassenger': '7 passagers',
        'EightPassenger': '8 passagers',
        'NinePassenger': '9 passagers',
        'From': 'De',
        'To': 'Vers',
        'LeavingFrom': 'Départ de',
        'GoingTo': 'Vers',
        'Depart': 'Départ',
        'Return': 'Retour',
        'RedeemMiles': 'Echanger des miles',
        'AdvancedMultiCitySearch': 'Recherche avancée ou multi-destinations',
        'Search': 'Rechercher',
        'SubmitSearch': ', Soumettre la recherche',
        'SearchHotels': ', Rechercher des hôtels',
        'SearchCars': ', Rechercher des voitures',
        'FromCurrentLocationPlaceholder': 'Utiliser l’emplacement actuel pour l’aéroport ou la ville de départ',
        'ToCurrentLocationPlaceholder': 'Utiliser l’emplacement actuel pour l’aéroport ou la ville de destination',
        'TravelType': 'Type de voyage',
        'SelectTravelType': 'Sélectionner le type de voyage',
        'Personal': 'Personnel',
        'Business':'Affaires',
        'AirPass': 'AirPass',
        'Company': 'Entreprise',
        'SelectCompany': 'Sélectionner une entreprise',
        'BusinessTraveler': 'Voyageur d’affaires',
        'SelectBusinessTraveler': 'Sélectionner un voyageur d’affaires',
        'LoadingText': 'Chargement en cours...',
        'Guest': 'INVITÉ',
        'SearchTravelerName': 'Rechercher le nom du voyageur',
        'mileRedemption': 'Vous n’avez pas encore débloqué vos avantages d’échange de milles.',
        'travelManagementPortal': 'Portail de gestion des voyages'
      },
      'pt': {
        'Flights': 'Voo',
        'Vacations': '',
        'RoundTrip': 'Ida e volta',
        'OneWay': 'Somente ida',
        'OnePassenger': '1 passageiro',
        'TwoPassenger': '2 passageiros',
        'ThreePassenger': '3 passageiros',
        'FourPassenger': '4 passageiros',
        'FivePassenger': '5 passageiros',
        'SixPassenger': '6 passageiros',
        'SevenPassenger': '7 passageiros',
        'EightPassenger': '8 passageiros',
        'NinePassenger': '9 passageiros',
        'From': 'De',
        'To': 'Para',
        'LeavingFrom': 'Partindo de',
        'GoingTo': 'Indo para',
        'Depart': 'Data de partida',
        'Return': 'Data de Retorno',
        'RedeemMiles': 'Resgatar milhas',
        'AdvancedMultiCitySearch': 'Busca avançada/várias cidades',
        'Search': 'Localizar voos',
        'FromCurrentLocationPlaceholder': 'Use a localização atual como aeroporto ou cidade de partida',
        'ToCurrentLocationPlaceholder': 'Use a localização atual como aeroporto ou cidade de destino'
      },
      'pt_BR': {
        'Flights': 'Voo',
        'Vacations': '',
        'RoundTrip': 'Ida e volta',
        'OneWay': 'Somente ida',
        'OnePassenger': '1 passageiro',
        'TwoPassenger': '2 passageiros',
        'ThreePassenger': '3 passageiros',
        'FourPassenger': '4 passageiros',
        'FivePassenger': '5 passageiros',
        'SixPassenger': '6 passageiros',
        'SevenPassenger': '7 passageiros',
        'EightPassenger': '8 passageiros',
        'NinePassenger': '9 passageiros',
        'From': 'De',
        'To': 'Para',
        'LeavingFrom': 'Partindo de',
        'GoingTo': 'Indo para',
        'Depart': 'Data de partida',
        'Return': 'Data de Retorno',
        'RedeemMiles': 'Resgatar milhas',
        'AdvancedMultiCitySearch': 'Busca avançada/várias cidades',
        'Search': 'Localizar voos',
        'SubmitSearch': ', Enviar pesquisa',
        'SearchHotels': ', Pesquisar hotéis',
        'SearchCars': ', Pesquisar carros',
        'FromCurrentLocationPlaceholder': 'Use a localização atual como aeroporto ou cidade de partida',
        'ToCurrentLocationPlaceholder': 'Use a localização atual como aeroporto ou cidade de destino',
        'TravelType': 'Tipo de viagem',
        'SelectTravelType': 'Selecionar tipo de viagem',
        'Personal': 'Pessoal',
        'Business':'Corporativa',
        'AirPass': 'AirPass',
        'Company': 'Empresa',
        'SelectCompany': 'Selecionar empresa',
        'BusinessTraveler': 'Viagem corporativa',
        'SelectBusinessTraveler': 'Selecionar passageiro corporativo',
        'LoadingText': 'Carregando...',
        'SearchTravelerName': 'Pesquisar nome do passageiro',
        'mileRedemption': 'Você ainda não desbloqueou seus benefícios de resgate de milhas. ',
        'travelManagementPortal': 'Portal de gerenciamento de viagens'
      }
    };
  }
