import { TranslationDataController } from "./translation-data-controller";


// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class NumberFormatController {

    private static locale = 'en_US';

    private static formatLocaleCode() {
        NumberFormatController.locale = TranslationDataController.retrieveLocale();
        return NumberFormatController.locale.replace('_', '-');
    }

    private static get getNumberFormatter() {
        return Intl.NumberFormat(NumberFormatController.formatLocaleCode());
    }

    static format(number: number): string {
        return NumberFormatController.getNumberFormatter.format(number);
    }
}
