import { AuthStatus } from "@adc/utilities/iauthstatusresponse";
import { TranslationDataController } from '@adc/utilities/translation-data-controller';
import axios from 'axios';
import data from "../../assets/booking-data.json";
import * as memberData from "../../assets/memberInfoMockData.json";
import type { IAuthStatusResponse } from "@adc/utilities/iauthstatusresponse";
import type { IAuthUserResponse } from "@adc/utilities/iauthuserresponse";
import type { ReactiveController, ReactiveControllerHost } from "lit";
import { ApolloQueryController } from '@apollo-elements/core';
import { MemberInformationQuery } from "./../components/booking-widget/src/graph/queries/memberInformationQuery";
import { InMemoryCache, ApolloClient, ApolloLink, HttpLink } from "@apollo/client";
import { version } from "graphql";

export class BookingDataController implements ReactiveController {
  host: ReactiveControllerHost;
  locale = TranslationDataController.retrieveLocale();
  queryController: ApolloQueryController <any>;
  private readonly baseUrl = document.location.hostname;
  private readonly graphRouterUrlDev = "https://iqa2.qa.aa.com/services/graphql";
  private graphRouterUrl = "/services/graphql";
  private transactionId = "";

  constructor(host: ReactiveControllerHost) {
    (this.host = host).addController(this);
    this.configureApollo();
    this.queryController = new ApolloQueryController(host, MemberInformationQuery, {noAutoSubscribe: true}); //new QueryController(this, this.memberInformationQuery);
  }

  hostConnected() {  
  }

  async getContentLinks() {
    const baseUrl = document.location.hostname;
    const protocol = document.location.protocol;

    const urlToFetch = `${protocol}//${baseUrl}/pubcontent/${this.locale}/fragments/homePage/booking.json`;
    if(baseUrl.includes("localhost")) {
      return Promise.resolve(JSON.parse(JSON.stringify(data)));
    } else {
      try {
        const response = await axios.get<any>(urlToFetch);
        return response.data;
      } catch (error) {
        console.log("api error:", error);
      }
    }
    return Promise.resolve(undefined);
  }

  async getUserAccountDetails(_advantageNumber: string) {
    const baseUrl = document.location.hostname;
    if(baseUrl.includes("localhost")) {
      return Promise.resolve(JSON.parse(JSON.stringify(memberData)));
    } else {
      try {
        const accessLevelRes = await axios.get<IAuthStatusResponse>('/loyalty/access-level');
        const accessLevelData = accessLevelRes.data;
        if(accessLevelData.status === AuthStatus.SECURED) {
          if(_advantageNumber) {
            const memberInfoRes = { aadvantageNumber : _advantageNumber } as IAuthUserResponse;
            return memberInfoRes;
          }
          const memberInfoRes = await axios.get<IAuthUserResponse>('/loyalty/api/member-information');
          return memberInfoRes.data;
        }
      } catch (error) {
        console.log("UserAccount Loyalty api error:", error);
      }
    }
    return Promise.resolve(undefined);
  }

  setTransactionId(transactionId: string) {
    if(transactionId) {
      this.transactionId = transactionId;
    }
  }

  setGraphRouterUrl(graphRouterUrl: string) {
    if(graphRouterUrl) {
      this.graphRouterUrl = graphRouterUrl;
    }
  }

  private configureApollo() {
    const cache = new InMemoryCache();
      window.__APOLLO_CLIENT__ = new ApolloClient({cache, link: this.apolloLink});
    return;
  }

  public get apolloLink() {
    return ApolloLink.from([
      new HttpLink({
        uri: this.baseUrl.indexOf("localhost") >= 0 ? this.graphRouterUrlDev : this.graphRouterUrl,
        headers: {
          "apollographql-client-name": "booking-widget-aacom",
          "apollographql-client-version": version,
          "x-transactionid": this.transactionId
        },
        credentials: 'include'
      })
    ]);
  }

  get version(): string {
    return version
  }

}
