import { GlobalDataController } from "../../../utilities/global-data-controller";
import { BookingDataController } from '@adc/utilities/booking-data-controller';
import { ExecuteLogMessage, Log } from '@adc/utilities/logger-controller';
import { TranslationDataController } from '@adc/utilities/translation-data-controller';
import { NumberFormatController } from "@adc/utilities/number-format-controller";
import { registerComponent as registerButton } from "@aileron/button";
import { registerComponent as registerCheckbox } from "@aileron/checkbox";
import { registerComponent as registerContentSwitcher } from "@aileron/content-switcher";
import { registerComponent as registerDatePicker } from "@aileron/date-picker";
import { registerComponent as registerDropdown } from "@aileron/dropdown";
import { registerComponent as registerIcon } from "@aileron/icon";
import { registerComponent as registerLink } from "@aileron/link";
import { registerComponent as registerLoader } from "@aileron/loading";
import { registerComponent as registerSelect } from '@aileron/select';
import { registerComponent as registerTextInput} from '@aileron/text-input';
import { registerComponent as registerAirportCode } from "@aileron-web/airport-code";
import { registerComponent as registerNotification } from "@aileron/notification";
import { LitElement, html } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { v4 as uuidv4 } from 'uuid';
import { GlobalTranslations } from '../../../utilities/global-translations';
import { version } from '../package.json';
import styles from "./styles.scss";
import { BookingWidgetTranslations } from './translations';
import { requestHeader, SearchTypes, PassengerTypes} from "./types/booking-details";
import type { BookingDetails, Passenger, Slice, MemberInformationRequest, LoyaltyInfo} from "./types/booking-details";
import type { IMemberInfo, ICompany} from "@adc/utilities/imemberinfo";

registerButton('hp-booking-widget');
registerIcon('hp-booking-widget');
registerCheckbox('hp-booking-widget');
registerContentSwitcher('hp-booking-widget');
registerDatePicker('hp-booking-widget');
registerDropdown('hp-booking-widget');
registerLink('hp-booking-widget');
registerLoader('hp-booking-widget');
registerSelect('hp-booking-widget');
registerAirportCode('hp-booking-widget');
registerTextInput('hp-booking-widget');
registerNotification('hp-booking-widget');
@customElement("adc-booking-widget")

export class BookingWidget extends LitElement {
  /*
   * @private
  */
  static styles = styles;
  private readonly bookingData = new BookingDataController(this);
  private readonly passengerValues = [
    "OnePassenger",
    "TwoPassenger",
    "ThreePassenger",
    "FourPassenger",
    "FivePassenger",
    "SixPassenger",
    "SevenPassenger",
    "EightPassenger",
    "NinePassenger"
  ];
  
  private readonly invalidAwardMiles = -1;
  @query("#vacations") vacations;
  @query("#tripType") tripType;
  @query("#passengerCount") passengerCount;
  @query("#passengerCountBusiness") passengerCountBusiness;
  @query(".form-container") formContainer;
  @query("#redeemMiles") awardCheckbox;
  @query("#multiCity") multiCityLink;
  @query("#mobile-redeem-miles") mobileAwardCheckbox;
  @query("#originAirportInput") bookingFrom;
  @query("#destinationAirportInput") bookingTo;
  @query("#datePicker-round-trip") datePickerRoundTrip;
  @query("#datePicker-one-way") datePickerOneWay;
  @query("#dropdown-travel-type") dropdownTravelType;
  @query("#dropdown-company") dropdownCompany;
  @query("#dropdown-business-traveler") dropdownBusinessTraveler;
  @query("#adc-business-traveler") businessTravel;
  @query("#booking-widget-client") apolloClient;
  @query("#gql-query") gqlQuery;
  @query("#travelTypeContainer") travelTypeContainer;
  @query("#companyName") companyName;
  @query("#loadingSpinner") loading;
  @query("#inlineNotification") inlineNotification;

  @property({ attribute: false }) multiCity;
  @property({type: Number}) loadTime: number;
  @property({ type: Boolean, reflect: false }) isOneWay = false;
  @property({ type: Boolean, reflect: false }) isBusiness = false;
  @property({ type: Boolean, reflect: false }) isPersonal = false;
  @property({ type: Boolean, reflect: false }) isAirPass = false;
  @property({ type: Boolean, reflect: false }) isAccountTravelManager = false;
  @property({ type: Boolean, reflect: false }) isVacations: boolean;
  @property({ type: Boolean, reflect: true }) isFlights =  true;
  @property({ type: String, reflect: true }) localeCode = "en_US";
  @property({ type: String, reflect: true, attribute: "url" }) graphRouterUrl = "/services/graphql";
  @property({ type: String, reflect: true, attribute: "transactionid" }) transactionId = "";
  @property({ type: String, reflect: true, attribute: "redeemmiles" }) redeemMiles = "false"; // this comes back as a string type
  @property({ type: String, reflect: true, attribute: "origin" }) origin = "";
  @property({ type: String, reflect: true, attribute: "destination" }) destination = "";
  @property({ type: String, reflect: true, attribute: "departuredate" }) departureDate = "";
  @property({ type: String, reflect: true, attribute: "advantagenumber" }) advantageNumber = "";
  @property({ type: String, reflect: true, attribute: "returndate" }) returnDate = "";
  @property({ type: Number, reflect: true,  attribute: "passengercount" }) passenger_count = 0;
  @property({ type: Number, reflect: true, attribute: "selectedawardmiles"})  selectedAwardMiles = -1;
  @property({ type: String, reflect: true, attribute: "triptype" }) trip_type = "roundTrip";
  @property({ type: String, reflect: true, attribute: "companybean" }) companyBean = "";
  @property({ type: String, reflect: true, attribute: "traveltype" }) travel_type = "";
  @property({ type: String, reflect: true }) awardMilesLabel = " Award Miles";
  @property({ type: String, reflect: true, attribute: "traveler" }) traveler: string;
  @property({ type: String, reflect: true }) company = "";
  @property({ type: [], reflect: true }) passengers: Passenger[] = [];
  @property({ type: [], reflect: true }) searchLocaleStorage: any[] = [];
  @property({type: Boolean, attribute: false}) _isTriplink;
  @property({type: Boolean, attribute: false}) _isAdvantageBusiness;
  @property({ type: Boolean, attribute: false }) showLoadingSpinner = false;
  @property({attribute: false}) businessCompanies;
  @property({ type: Array }) searchLocales = ["en_US", "es_US", "en_CA", "fr_CA"];

  formatDateLocales = ["en_CA", "fr_CA"];
  displayVacationList: boolean;
  memberInformationRequest: MemberInformationRequest;
  singleCompany: any;
  businessMemberInfo: IMemberInfo;
  travelType: any;
  travelerValue: string;
  loggedInAdvantageNumber: string;
  userLoggedInDetails: any;
  ancillaryLinks: any;
  commonLinks: any;
  startTime: number;
  convertedTripType: string;
  s: string;
  thresholdIndicator: boolean;
  showThresholdNotification: boolean;
  showSelectedAwardMiles: boolean;
  selectedCompany: ICompany;
  isInSpa: boolean;

  constructor() {
    super();
      this.bookingData.getContentLinks().then((linkData) => {
      if(linkData){
        this.ancillaryLinks = linkData.links.ancillaryLinks;
        this.commonLinks = linkData.links.commonLinks;
      }
    })
  }

  connectedCallback() {
    super.connectedCallback();
    this.transactionId = uuidv4();
    this.bookingData.setGraphRouterUrl(this.graphRouterUrl);
    this.bookingData.setTransactionId(this.transactionId);
    this.bookingData.queryController.client?.setLink(this.bookingData.apolloLink);

    this.convertedTripType = this.convertTripType(this.trip_type);

    this.bookingData.getUserAccountDetails(this.advantageNumber).then( async (userAccountDetails: any) => {
      this.userLoggedInDetails = userAccountDetails;
      if(userAccountDetails) {
        this.loggedInAdvantageNumber =  userAccountDetails?.aadvantageNumber;
        this.showLoadingSpinner = true;
          this.bookingData.queryController.variables = {
            advantageNumber: userAccountDetails?.aadvantageNumber
           }
        await this.bookingData.queryController.executeQuery().then(() => {
          this.businessMemberInfo = this.bookingData.queryController?.data?.memberInformation;
          this._isAdvantageBusiness = this.businessMemberInfo?.programs.advantageBusiness.companies.length > 0;
          this._isTriplink = (this.businessMemberInfo?.programs.tripLink.advantageNumber || this.businessMemberInfo?.programs.tripLink.discountInfos.length > 0) && !this._isAdvantageBusiness;
          this.businessCompanies = this._isAdvantageBusiness ? this.businessMemberInfo.programs.advantageBusiness.companies : (this.businessMemberInfo.programs.tripLink.discountInfos[0] ? [ this.businessMemberInfo.programs.tripLink.discountInfos[0] ] : []);
          this.showLoadingSpinner = false;
          this.selectedCompany = this.getCompanyFromRequest();
          this._setValuesFromRequest(this.origin, this.destination);
        }).catch((error) => {
          ExecuteLogMessage(this, error);
          this.showLoadingSpinner = false;
        });
      } else {
        this._setValuesFromRequest(this.origin, this.destination);
      }

    });
  }

  @Log
  render() {
    this.startTime = new Date().getTime();
    this.localeCode = TranslationDataController.retrieveLocale();
    TranslationDataController.getLocaleTranslations(this.localeCode, BookingWidgetTranslations);
    TranslationDataController.getLocaleTranslations(this.localeCode, GlobalTranslations);
    this.displayVacationList = this.shouldShowLocalesList();
    this.isInSpa = !!document.querySelector('app-home-page-body');

    return html`
    ${this.showLoadingSpinner ? this.displaySpinner() : ''}
    <div class="booking-container">
        <div class="trip-type-container">
          <div ?hidden='${this.displayVacationList}' class="content-switch">
            ${!this.isInSpa && this._isMobile ? '' : this.renderContentSwitcher()}
          </div>
          <div class="dropdown-container">
            <hp-booking-widget-dropdown .options=${[TranslationDataController.translations['RoundTrip'],TranslationDataController.translations['OneWay']]}
               ghost small value="${TranslationDataController.translations[this.convertedTripType]}" id="tripType" data-cy="trip-type" @click="${this._handleSelectTripType}" @keydown="${this._handleTripTypeKeydown}" required="true">
               <adc-icon icon="navigation:arrow-double-horizontal" slot="prepend-icon" outlined size="24"></adc-icon>
            </hp-booking-widget-dropdown>
          ${this.renderPassengersDropdownType()}
          </div>
        </div>
        <div class="form-container" id="formContainer">
            <hp-booking-widget-airport-code
              name="From"
              id="originAirportInput"
              data-cy="booking-from"
              placeholder="${TranslationDataController.translations['LeavingFrom']}"
              label-text="${TranslationDataController.translations['From']}"
              icon-label-text="${TranslationDataController.translations['FromCurrentLocationPlaceholder']}"
              ?recentSearches="${true}"
              ?geolocator="${true}"
              @recent-search-selected="${this.onRecentSearchSelected}">
            </hp-booking-widget-airport-code >
            <hp-booking-widget-airport-code
              name="To"
              class="booking-to"
              id="destinationAirportInput"
              data-cy="booking-to"
              placeholder="${TranslationDataController.translations['GoingTo']}"
              label-text="${TranslationDataController.translations['To']}"
              icon-label-text="${TranslationDataController.translations['ToCurrentLocationPlaceholder']}"
              ?recentSearches="${true}"
              ?geolocator="${true}"
              @recent-search-selected="${this.onRecentSearchSelected}"
              >
            </hp-booking-widget-airport-code>
            <hp-booking-widget-date-picker
              id="datePicker-one-way"
              data-cy="booking-datepicker-one-way"
              ?stack-inputs=${window.innerWidth > 800 ? false : true}
              kind=${window.innerWidth > 800 ? "dropdown" : "dialog"}
              weekday-format="narrow"
              input-kind="text"
              max-days-out="331"
              single-day-selection
              first-selection-text="${TranslationDataController.translations['Depart']}"
              locale="${this.localeCode}"
              first-pre-selection >
            </hp-booking-widget-date-picker>
            <hp-booking-widget-date-picker
              id="datePicker-round-trip"
              data-cy="booking-datepicker-round-trip"
              ?stack-inputs=${window.innerWidth > 800 ? false : true}
              kind=${window.innerWidth > 800 ? "dropdown" : "dialog"}
              weekday-format="narrow"
              first-selection-text="${TranslationDataController.translations['Depart']}"
              second-selection-text="${TranslationDataController.translations['Return']}"
              input-spacing=${window.innerWidth > 800 ? 24 : 12}
              input-kind="text"
              max-days-out="331"
              locale="${this.localeCode}"
              first-pre-selection
              second-pre-selection
              >
            </hp-booking-widget-date-picker>
            ${!this.isCorporate() || (this.travelTypeContainer && this.travelTypeContainer.style.display === "none") ? html`
              <hp-booking-widget-checkbox name="basic" label-text="${TranslationDataController.translations['RedeemMiles']}" class="mobile-redeem-miles" id="mobile-redeem-miles" orientation="horizontal" label-position="right" .disabled=${this.isBusiness || this.isAirPass} ?checked=${this.redeemMilesFromRequest()}></hp-booking-widget-checkbox>
              <hp-booking-widget-button
                @click=${!this.isVacations ? this._handleSearch : this._vacationSearch}
                onclick=${this.isVacations ? this._handleAnalytics(TranslationDataController.translations['Vacations']): ''}
                data-cy="booking-search"
                class="search"
                kind="primary"
                type="button"
                size="fullwidth" >
                  ${TranslationDataController.translations['Search']}${this.isVacations ? this.displayNewWindowIcon() : ""}
                  ${this.displayScreenReaderText()}
              </hp-booking-widget-button>
              `: ""
            }
        </div>
        ${this.isCorporate() ? this.displayCorporateForm() : ""}
        <div class="show-inline-notification-desktop">${this.showThresholdNotification && this.isBusiness && this.isAccountTravelManager && this.isFlights ? this.renderInlineNotification(): ''}</div>
        <div class="checkbox-link-container">
          <hp-booking-widget-checkbox class="desktop-redeem-miles" id="redeemMiles" name="basic" label-text="${
            TranslationDataController.translations['RedeemMiles']
          }" orientation="horizontal" label-position="right" data-cy="award-checkbox" .disabled=${(this.isBusiness && !this.thresholdIndicator) || this.isAirPass} ?checked=${this.redeemMilesFromRequest()}></hp-booking-widget-checkbox>
           <div class="content-links">
              ${this.isInSpa || (GlobalDataController._isHomePage() && !this._isMobile) ? this.displayContentLinks(this.ancillaryLinks, true): ''}
              ${!this.isVacations ? this.displayAdvancedSearchLink() : '' }     
           </div>    
        </div>
        ${!this.isInSpa ? this.displayContentLinks(this.commonLinks, false) : ''}
      </div>
    `;
  }

  protected async getUpdateComplete(): Promise<boolean> {
    const result = await super.getUpdateComplete();
    this.loadTime = new Date().getTime() - this.startTime;
    return result;
  }

  renderContentSwitcher() {
    return html `
      <hp-booking-widget-content-switcher class="content-switch" selected="flights" with-checkmark-icon data-cy="content-switcher">
              <hp-booking-widget-content-switch class="content-switch-tab" value="${
                TranslationDataController.translations['Flights']
                      }" @click="${this._flightsVacationsView}" @keydown="${
                   this._handleContentSwitcherKeydown
                  }" selected>${TranslationDataController.translations['Flights']}</hp-booking-widget-content-switch>
                  <hp-booking-widget-content-switch class="content-switch-tab" value="${TranslationDataController.translations['Vacations']}" @click="${
                    this._flightsVacationsView
                  }"  @keydown="${this._handleContentSwitcherKeydown}" data-cy="vacation-content">${
                    TranslationDataController.translations['Vacations']
              }</hp-booking-widget-content-switch>
            </hp-booking-widget-content-switcher>
    `
  }

  renderInlineNotification() {
    return html`
      <hp-booking-widget-inline-notification id="inlineNotification" class="adc-inline-notification miles-notification-link" kind="information" role="alert" fullwidth id="adcNotification_0" variant="default">
        <span slot="title" class="milesNotificationTitle">
            ${TranslationDataController.translations['mileRedemption']}
          <hp-booking-widget-link
            icon="new-window",
            hidden-label-text="${TranslationDataController.translations['newWinExtText']}"
            href="https://aadvantagebusiness.aa.com/app/home">
            ${TranslationDataController.translations['travelManagementPortal']}
          </hp-booking-widget-link>
        </span>
      </hp-booking-widget-inline-notification>
  `}

  renderPassengersDropdownType() {
      return html`
    <hp-booking-widget-dropdown class="passengerCountBusiness" .disabled=${this.isBusiness && !(this.travelTypeContainer && this.travelTypeContainer.style.display === "none")} .options=${this.renderPassengers()} id='passengerCountBusiness'
      ghost small value="${(this.travelType === 'AirPass' && !this.isVacations) && (this.passenger_count > 0 && this.passenger_count <= 2) ? TranslationDataController.translations[this.passengerValues[this.passenger_count - 1]] : TranslationDataController.translations['OnePassenger']}" required="true">
       <adc-icon icon="operation:account" slot="prepend-icon" size="24"></adc-icon>
     </hp-booking-widget-dropdown>
     <hp-booking-widget-dropdown class="passengerCount" .options=${this.renderPassengers()} id='passengerCount'
      ghost small value="${this.passenger_count > 0 ? TranslationDataController.translations[this.passengerValues[this.passenger_count - 1]] : TranslationDataController.translations['OnePassenger']}" required="true">
        <adc-icon icon="operation:account" slot="prepend-icon" size="24"></adc-icon>
    </hp-booking-widget-dropdown>
     `
  }

  displayAdvancedSearchLink() {
    return html `
      <hp-booking-widget-link href="/booking/find-flights?from=comp_homewidget" id="multiCity" icon="chevron-right" data-cy="notification-link">${
        TranslationDataController.translations['AdvancedMultiCitySearch']}
      </hp-booking-widget-link>
    `
  }

  displayContentLinks(data, inline ) {
    return html`
      ${data?.map(
        (linkData) => html`
          <hp-booking-widget-link
            class="${inline ? 'ancillary-link' : 'common-link'}"
            icon="${linkData.newWin ? 'new-window' : 'chevron-right'}"
            target="${linkData.newWin ? '_blank': '_self'}"
            onclick="${this._handleAnalytics(linkData.linkText)}"
            hidden-label-text="${this.linkHiddenText(linkData)}"
            href="${linkData.href}">
              ${linkData.linkText}
          </hp-booking-widget-link>
        `)}
      `;
  }

  linkHiddenText(data) {
    let hiddenText = "";
    if(data?.href.includes('hotels')){
      hiddenText = TranslationDataController.translations['SearchHotels'];
    } else if(data?.href.includes('car')) {
      hiddenText = TranslationDataController.translations['SearchCars'];
    }
    hiddenText = data?.newWin ? data?.external ? hiddenText += TranslationDataController.translations['newWinExtText'] : hiddenText += TranslationDataController.translations['newWinText'] : "";

    return hiddenText;
  }

  displayScreenReaderText() {
    return html `<span class="sr-only">${TranslationDataController.translations['SubmitSearch']}</span>`
  }

  //* Find & match list locales */
  shouldShowLocalesList() {
    return !this.searchLocales.includes(this.localeCode);
  }

  private _handleAnalytics(linkText: any) {
    return `if(typeof s !== "undefined"){s.tl(this,"e","${linkText}");}`
  }

  private _setValuesFromRequest(origin?, destination?, isRecentSearch = false) {
    const tripIcon =  this.tripType.querySelector("adc-icon");

    this.bookingFrom.renderRoot.querySelector("#airport-code-input")!.value = this.businessMemberInfo?.memberPreferences?.bookingPreference?.homeAirport && !destination ? this.businessMemberInfo?.memberPreferences?.bookingPreference?.homeAirport : origin ? origin?.toUpperCase() : "";
    this.bookingTo.renderRoot.querySelector("#airport-code-input")!.value = destination ? destination?.toUpperCase() : "";
    this.datePickerOneWay.shadowRoot.querySelector("#first-input").value = this.departureDate ? this._normalizeDate(this.departureDate, true) : "";
    this.datePickerRoundTrip.shadowRoot.querySelector("#first-input").value = this.departureDate ? this._normalizeDate(this.departureDate, true) : "";
    this.datePickerRoundTrip.shadowRoot.querySelector("#second-input").value = this.returnDate ? this._normalizeDate(this.returnDate, true) : "";

    if (this.convertedTripType === 'OneWay') {
      this.isOneWay = true;
      this.formContainer.querySelector("#datePicker-one-way").style.display = this.isVacations ? "none" : "block";
      this.formContainer.querySelector("#datePicker-round-trip").style.display = this.isVacations ? "block" : "none";
      tripIcon?.setAttribute("icon", "navigation:arrow-single-right");
    } else if (this.convertedTripType === 'RoundTrip') {
      this.isOneWay = false;
      this.formContainer.querySelector("#datePicker-one-way").style.display = "none";
      this.formContainer.querySelector("#datePicker-round-trip").style.display = "block";
      tripIcon?.setAttribute("icon", "navigation:arrow-double-horizontal");
    }

    if (isRecentSearch) {
      this.travelType = '';
      let listItems = this.dropdownTravelType?.querySelector("hp-booking-widget-dropdown").shadowRoot?.querySelector(".adc-listbox").renderRoot.querySelectorAll(".aileron-listbox__list-item");
      if (listItems) {
        listItems.forEach((listItem) => {
          if (listItem.getAttribute('aria-selected') === 'true') {
            this.dropdownTravelType.querySelector("hp-booking-widget-dropdown").value = '';
            listItem.setAttribute('aria-selected', 'false');
            this.dropdownTravelType.querySelector("hp-booking-widget-dropdown").shadowRoot.querySelector("hp-booking-widget-dropdown-listbox").value = '';
          }
        });
      }
      this._handleTravelTypeSelect();
    } else {
    switch (this.travel_type) {
      case 'business':
        this.travelType = 'Business';
        break;
      case 'personal':
        this.travelType = 'Personal';
        this.selectedAwardMiles = -1;
        this.showSelectedAwardMiles = false;
        break;
      case 'airpass':
        this.travelType = 'AirPass';
        break;
      default:
        this.travelType = '';
        break;
    }

    this.isBusiness = this.travelType === 'Business';
    this.company = this.selectedCompany?.name;
    this.isAccountTravelManager = this.getLoggedInTravelerRole();
    this.travelerValue = this.getTravelerFromRequest();

    if (!this.selectedCompany?.thresholdIndicator) {
      this.showThresholdNotification = true;
    } else {
      this.thresholdIndicator = true;
    }

    this.selectedAwardMiles = this.selectedCompany?.awardEligibleMiles;
    this.showSelectedAwardMiles = this.selectedAwardMiles !== this.invalidAwardMiles;

    if (this.showSelectedAwardMiles) {
      this.displayAwardMiles();
      }
    }
  }

  private getCompanyFromRequest() {
    return this.companyBean ? this.businessCompanies?.find(company => company.accountNumber === this.companyBean) : {};
}

  private redeemMilesFromRequest() {
    return this.redeemMiles === "true";
  }

  private getTravelerFromRequest() {
    const travelerFromRequest = this.traveler ? this.businessCompanies?.find(businessTraveler => businessTraveler?.name === this.selectedCompany?.name)?.travelers?.filter(traveler => traveler.uuid === this.traveler)[0] : "";
    if(this.traveler === 'GUEST') {
      return TranslationDataController.translations['Guest'];
    }
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return  travelerFromRequest ? `${travelerFromRequest.lastName}, ${travelerFromRequest.firstName} (${travelerFromRequest.maskedAccountNumber})` : TranslationDataController.translations['SelectBusinessTraveler'];
  }

  private getLoggedInTravelerRole() {
    const loggedInTraveler = this.companyBean ? this.businessCompanies?.find(businessTraveler => businessTraveler?.name === this.selectedCompany?.name)?.travelers?.filter(traveler => traveler.isLoggedInUser)[0] : "";
    return loggedInTraveler?.role === "Account Travel Manager";
  }

  private isCorporate() {
    if(this._isAdvantageBusiness || this._isTriplink || this.businessMemberInfo?.programs?.airpass.airpassProgramDetails.length) {
      return true;
    }
    return false;
  }

  private displaySpinner() {
    return html `<hp-booking-widget-loading state="active" label-text="${TranslationDataController.translations['LoadingText']}" id="loadingSpinner"></hp-booking-widget-loading>`;
  }

  // when the vacations button is clicked then remove the trip type, redeem miles and show a new window icon next to search.
  private _flightsVacationsView(event) {
    const contentValue = event.target.getAttribute("value");
    if (contentValue === TranslationDataController.translations['Vacations']) {
      this.isFlights = false;
      this.isVacations = true;
      this.passengerCount.style.visibility = "visible";
      this.passengerCountBusiness.style.visibility = "hidden";
      this.passengerCountBusiness.style.position = "absolute";
      this.tripType.style.display = "none";
      this.awardCheckbox.style.visibility = "hidden";
      this.formContainer.querySelector("#datePicker-one-way").style.display = "none";
      this.formContainer.querySelector("#datePicker-round-trip").style.display = "block";
      if(this.travelTypeContainer) {
        this.travelTypeContainer.style.display = "none";
      }
    } else if (contentValue === TranslationDataController.translations['Flights']) {
      this.isFlights = true;
      this.isVacations = false;
      this.tripType.style.display = "flex";
      this.awardCheckbox.style.visibility = "visible";
      if(this.travelTypeContainer) {
        this.travelTypeContainer.style!.display = "flex";
        this._handleTravelTypeSelect();
      }
      if (this.isOneWay) {
        this.formContainer.querySelector("#datePicker-one-way").style.display = "block";
        this.formContainer.querySelector("#datePicker-round-trip").style.display = "none";
      }
    }
  }

  private _handleSelectTripType() {
    // Check if select value is RT/OW and hide the arrial datepicker based on that.
    const tripValue = this.tripType.getAttribute("value");
    const tripIcon =  this.tripType.querySelector("adc-icon");
      if (tripValue === TranslationDataController.translations['RoundTrip']) {
        this.isOneWay = false;
        this.formContainer.querySelector("#datePicker-one-way").style.display = "none";
        this.formContainer.querySelector("#datePicker-round-trip").style.display = "block";
        tripIcon?.setAttribute("icon", "navigation:arrow-double-horizontal");
        return;
      }
      if (tripValue === TranslationDataController.translations['OneWay']) {
        this.isOneWay = true;
        this.formContainer.querySelector("#datePicker-one-way").style.display = "block";
        this.formContainer.querySelector("#datePicker-round-trip").style.display = "none";
        tripIcon?.setAttribute("icon", "navigation:arrow-single-right");
        return;
      }
    }

  private _handleContentSwitcherKeydown(event) {
    if (event.key === "Enter" || event.key === " ") {
      this._flightsVacationsView(event);
    }
  }

  private _handleTripTypeKeydown(event) {
    if (event.key === "Enter" || event.key === " ") {
      this._handleSelectTripType();
    }
  }

  displayNewWindowIcon() {
    return html`
      <hp-booking-widget-icon
        slot="trailing-icon"
        icon="navigation:new-window"
        label-text=${TranslationDataController.translations['newWinExtText']}
        size="24"
      ></hp-booking-widget-icon>
    `;
  }

  displayCorporateForm() {
    return html `
      <div class="form-container" id="travelTypeContainer">
       <div class="business-dropdown" id="dropdown-travel-type">
          <label class="label-dropdown" id="labelTravelType">${TranslationDataController.translations['TravelType']}</label>
          <hp-booking-widget-dropdown aria-labelledby="labelTravelType" .options=${this.renderTravelTypes()} trigger-label=${TranslationDataController.translations['SelectTravelType']}
            @keydown=${this._handleTravelTypeSelect} @click=${this._handleTravelTypeSelect} data-cy="travel-type-dropdown" .value="${TranslationDataController.translations[this.travelType]}">
            <adc-icon icon="operation:account" slot="prepend-icon" size="24"></adc-icon>
          </hp-booking-widget-dropdown>
       </div>
       ${(this.isBusiness && this.businessCompanies?.length !== 0) ? this.displayCompanyDropDown() : this.displayPlaceHolderDropDown(true)}
       ${((this.isBusiness && this.isAccountTravelManager) && this._isAdvantageBusiness) ? this.displayBusinessTravelerDropDown() : this.displayPlaceHolderDropDown(!this.isOneWay)}
       <div class="show-inline-notification-mobile">${this.showThresholdNotification && this.isBusiness && this.isAccountTravelManager ? this.renderInlineNotification(): ''}</div>
       <hp-booking-widget-checkbox name="basic" label-text="${TranslationDataController.translations['RedeemMiles']}" class="mobile-redeem-miles" id="mobile-redeem-miles" orientation="horizontal" label-position="right" .disabled=${(this.isBusiness && !this.thresholdIndicator) || this.isAirPass}></hp-booking-widget-checkbox>
       <hp-booking-widget-button
          @click=${!this.isVacations ? this._handleSearch : this._vacationSearch}
          onclick=${this.isVacations ? this._handleAnalytics(TranslationDataController.translations['Vacations']): ''}
          data-cy="booking-search"
          class="search-corp"
          kind="primary"
          type="button"
          size="fullwidth" >
            ${TranslationDataController.translations['Search']}
            ${this.displayScreenReaderText()}
        </hp-booking-widget-button>
      </div>
    `
  }

  displayCompanyDropDown() {
      return html `
        <div class="business-dropdown" id="dropdown-company" data-cy="company-dropdown">
          ${this.businessCompanies?.length === 1 ? this.renderSingleCompanyInput() : this.renderCompanyDropdown() }
        </div>
      `
  }

  renderSingleCompanyInput(){
    const singleCompanyName = this._isAdvantageBusiness ? this.businessCompanies[0]?.name : this.businessCompanies[0]?.companyName;
    const awardMiles = this.businessCompanies[0]?.awardEligibleMiles;
    const singleAwardMiles = awardMiles === this.invalidAwardMiles || awardMiles === undefined || !awardMiles ? '' : NumberFormatController.format(awardMiles) + this.awardMilesLabel;
    return html `
      <hp-booking-widget-text-input id="companyName" helper-text="${singleAwardMiles}" label-text="${TranslationDataController.translations['Company']}" class="one-company" readonly type="text" value="${singleCompanyName}"></hp-booking-widget-text-input>
    `
  }

  renderCompanyDropdown() {
    return html `
    <label class="label-dropdown" id="labelCompany">${TranslationDataController.translations['Company']}</label>
      <hp-booking-widget-dropdown id="companyName" aria-labelledby="labelCompany" aria-controls="entityMiles" .options=${this.businessCompanies?.map((el) => { return el.name; })}
        trigger-label="${TranslationDataController.translations['SelectCompany']}" @keydown=${this._handleCompanySelect} @click=${this._handleCompanySelect} .value="${this.company}">
      </hp-booking-widget-dropdown>
        ${this.company && this.selectedAwardMiles !== this.invalidAwardMiles ? this.displayAwardMiles() : ''}
        
    `
  }


  displayAwardMiles() {
    return html `
       <div id="entityMiles" role="region" aria-live="assertive">${NumberFormatController.format(this.selectedAwardMiles) + this.awardMilesLabel}</div>
       `
  }

  searchPlaceholderText(){
    // When clicking on business traveler dropdown button, it will add on the search button placeholder text and adjust the min width of the dropdown menu to the longest name (fit-content)
    const searchText = this.businessTravel.shadowRoot.querySelector(".adc-listbox").shadowRoot.querySelector("#adc-lb-search");
    searchText.setAttribute("placeholder", TranslationDataController.translations['SearchTravelerName']);
    if(window.innerWidth > 800) {
      const adjustMenuPanel = this.businessTravel.shadowRoot.querySelector(".aileron-dropdown__menu")
      adjustMenuPanel.style.width = "auto";
      adjustMenuPanel.style.maxWidth = "493px";
    }
  }

  displayBusinessTravelerDropDown() {
    return html `
      <div class="business-dropdown" id="dropdown-business-traveler">
        <label class="label-dropdown" id="labelBusinessTravel">${TranslationDataController.translations['BusinessTraveler']}</label>
        <hp-booking-widget-dropdown aria-labelledby="labelBusinessTravel" .options=${[TranslationDataController.translations['Guest'], ...this.formatCompanyTravelers(this.company)]} trigger-label="${TranslationDataController.translations['SelectBusinessTraveler']}" include-search="true" id="adc-business-traveler" @click="${this.searchPlaceholderText}" data-cy="business-traveler-dropdown" .value="${this.travelerValue}">
          <adc-icon icon="operation:account" slot="prepend-icon" size="24"></adc-icon>
        </hp-booking-widget-dropdown>
      </div>
    `
  }

  displayPlaceHolderDropDown(whiteSpaceHolder) {
    if(window.innerWidth > 800 && whiteSpaceHolder){
      return html`
      <div>
      </div>`
    }
      return html ``;
  }

  private async _handleSearch() {
    const bookingDetails: BookingDetails = {
      loyaltyInfo: this._getloyaltyInfo(),
      passengers: this._getPassengerDetails(),
      requestHeader,
      slices: this.slices,
      tripOptions: {
        searchType: this._searchType,
        locale: this.localeCode,
        travelType: this.travelType?.toUpperCase() || ""
      }
    };
    this.storeLocalStorage(this.slices);
    const url = "/booking/flight-search";

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(bookingDetails),
      });

      if (response.ok) {
        const responseData = await response.json();
        // Use window.open with the response URL
        window.open(responseData.url, "_self");
      } else {
        console.error("Failed to make the POST request");
      }
    } catch (error) {
      console.error("Error:", error);
    }

  }

  private _vacationSearch() {
    let env;
    if (
      document.location.hostname.includes("stage.aa.com") ||
      document.location.hostname.includes("qa.aa.com") ||
      document.location.hostname.includes("localhost")
    ) {
      env = "https://stage.aavacations.com";
    } else {
      env = "https://www.aavacations.com";
    }
    const vacationUrl =
      `${env}/deeplink?ADID=DSP%7C%7C20240923%7CAAV%7CAAV%7CLNK%7C%7CAileron-booking-widget` +
      `&` +
      `searchType=matrix` +
      `&` +
      `adults=${this._getPassengerCount()}` +
      `&` +
      `rooms=1` +
      `&` +
      `serviceclass=coach` +
      `&` +
      `from_date=${this.slices[0]?.departureDate}` +
      `&` +
      `to_date=${this.slices[1]?.departureDate}` +
      `&` +
      `from_city_code=${this.slices[0]?.origin}` +
      `&` +
      `to_city_code=${this.slices[0]?.destination}` +
      `&` +
      `to_time=0` +
      `&` +
      `from_time=0`;
    window.open(vacationUrl, "_blank");
  }

  private storeLocalStorage(submitData) {
    const onSearchSubmit = submitData;
    const hasValidSearchData = onSearchSubmit?.[0]?.origin && onSearchSubmit?.[0]?.destination &&
                           ((this.isOneWay && this._checkDateFormat(onSearchSubmit?.[0]?.departureDate)) ||
                            (!this.isOneWay && this._checkDateFormat(onSearchSubmit?.[0]?.departureDate) && this._checkDateFormat(onSearchSubmit?.[1]?.departureDate)));

    if (hasValidSearchData) {
      const rewardMilesCheck = this.awardCheckbox?.shadowRoot?.querySelector("#checkbox").checked;
      const existingStorageData = localStorage.getItem('AA_FlightSearch');
      const originDate = this.localStorageDateFormat(onSearchSubmit?.[0]?.departureDate);
      const destinationDate = onSearchSubmit?.[1]?.departureDate ? this.localStorageDateFormat(onSearchSubmit?.[1]?.departureDate) : '';

      const localStorageParam = {
        originAirport: (onSearchSubmit?.[0]?.origin)?.toUpperCase(),
        destinationAirport: (onSearchSubmit?.[0]?.destination)?.toUpperCase(),
        tripType: this.isOneWay ? 'oneWay' : 'roundTrip',
        awardBooking: rewardMilesCheck ? true: false,
        originTravelDate: originDate ? originDate?.travelDate : '',
        destinationTravelDate: destinationDate ? destinationDate?.travelDate : '',
        createDate: new Date().toISOString(),
        expired: false
      };
      const elementIdValue = onSearchSubmit?.[0]?.origin + ' to ' + onSearchSubmit?.[0]?.destination + ' ' + originDate?.monthDay;

      localStorageParam['elementID'] = onSearchSubmit.length > 1 ?  (elementIdValue + ' - ' + destinationDate?.monthDay) : elementIdValue;


      if (existingStorageData) {
        const parsedData = JSON.parse(existingStorageData);
        if (Array.isArray(parsedData)) {
          this.searchLocaleStorage = parsedData.length > 4 ? parsedData.slice(0, 4) : parsedData;
        }
      }

      this.searchLocaleStorage.unshift(localStorageParam);
      localStorage.setItem('AA_FlightSearch', JSON.stringify(this.searchLocaleStorage));
    }

  }

  localStorageDateFormat(date): any {
    const dateObj:any = {};
    const normalizedDate = this._normalizeDate(date);
    const convertDate = new Date(normalizedDate.replace(/-/g, '\/'));
    const monthAbbreviation = this.formatMonth(convertDate, this.localeCode) || this.formatMonth(convertDate, "en-US");
    const dayOfMonth = convertDate?.getDate();

    dateObj.travelDate = convertDate ? {
      dateTime: date ? convertDate?.toISOString() : null,
      year: date ? convertDate?.getFullYear() : null,
      monthOfYear: date ? (convertDate?.getMonth() + 1) : null,
      dayOfMonth: date ? dayOfMonth : null
    } : '';
    dateObj.monthDay = convertDate ? `${monthAbbreviation} ${dayOfMonth}` : '';

    return dateObj;
  }

  formatMonth(date, locale) {
    return date?.toLocaleString(locale.replace('_', '-'), {month: 'short'})
  }

  private get slices(): Slice[] {
    const slices: Slice[] = [];
    if (this.isOneWay && this.isFlights) {
      slices.push({
        allCarriers: false,
        cabin: "",
        departureDate: this.datePickerOneWay.shadowRoot.querySelector("#first-input").value,
        destination: this.bookingTo.renderRoot.querySelector("#airport-code-input").value,
        destinationNearbyAirports: false,
        origin: this.bookingFrom.renderRoot.querySelector("#airport-code-input").value,
        originNearbyAirports: false
      });
    } else {
      slices.push({
        allCarriers: false,
        cabin: "",
        departureDate: this.datePickerRoundTrip.shadowRoot.querySelector("#first-input").value,
        destination: this.bookingTo.renderRoot.querySelector("#airport-code-input").value,
        destinationNearbyAirports: false,
        origin: this.bookingFrom.renderRoot.querySelector("#airport-code-input").value,
        originNearbyAirports: false
      });

      slices.push({
        allCarriers: false,
        cabin: "",
        departureDate: this.datePickerRoundTrip.renderRoot.querySelector("#second-input").value,
        destination: this.bookingFrom.renderRoot.querySelector("#airport-code-input").value,
        destinationNearbyAirports: false,
        origin: this.bookingTo.renderRoot.querySelector("#airport-code-input").value,
        originNearbyAirports: false
      });
    }
    return slices;
  }

  private get _isMobile() {
    const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regex.test(navigator.userAgent);
  }

  private get _searchType(): SearchTypes {
    const isAwardChecked =
      (this.awardCheckbox.shadowRoot.querySelector("#checkbox").checked && !this._isMobile) ||
      (this.mobileAwardCheckbox.shadowRoot.querySelector("#checkbox").checked && this._isMobile)
    let searchType = SearchTypes.REVENUE;
    if (isAwardChecked) {
      searchType = SearchTypes.AWARD;
    }
    return searchType;
  }

  private _normalizeDate(travelDate, isForDatePicker = false) {
    const shouldFormatDate = this.formatDateLocales.includes(this.localeCode);
    if(isForDatePicker) {
      let datePattern = /^(0?[1-9]|1[0-2])\/(1[3-9]|2[0-9]|3[0-1])\/\d{4}$/;
      if(datePattern.test(travelDate)) {
        return shouldFormatDate ? this.swapMonthDay(travelDate) : travelDate;
      } else {
        let datePattern = /^(1[3-9]|2[0-9]|3[0-1])\/(0?[1-9]|1[0-2])\/\d{4}$/;
        if(datePattern.test(travelDate)) {
          return !shouldFormatDate ? this.swapMonthDay(travelDate) : travelDate;
        }
      }
      return travelDate;
    }
    return shouldFormatDate ? this.swapMonthDay(travelDate) : travelDate;

  }

  private swapMonthDay(travelDate) {
    return travelDate.replace(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/, "$2/$1/$3");
  }

  private _checkDateFormat(date) {
    const datePattern = /^\d{1,4}[-/]\d{1,2}[-/]\d{1,4}$/;
    return (date && datePattern.test(date)) ? true : false;
  }

  private _getloyaltyInfo(): LoyaltyInfo {
    const loyaltyInfo: LoyaltyInfo = {
      loyaltyId: this.loggedInAdvantageNumber ? this.loggedInAdvantageNumber : "",
      bean: "",
      tripLink: (this._isTriplink && this.isBusiness),
      airPass: this.isAirPass
    };

    if ((!this.userLoggedInDetails) || !this.isCorporate()) {
      return loyaltyInfo;
    }

    if (this.travelType === 'Business' && this._isAdvantageBusiness) {
      if(this.businessCompanies?.length === 1) {
        loyaltyInfo.bean = this.businessCompanies[0]?.accountNumber;

      }
      else {
        const selectedCompany = this.dropdownCompany.querySelector("hp-booking-widget-dropdown").value;
        const filterCompany = this.businessCompanies?.filter(companyInfo => companyInfo?.name === selectedCompany);
        if (filterCompany.length) {
          loyaltyInfo.bean = filterCompany[0].accountNumber;
        }
      }

    }

    return loyaltyInfo;
  }

  private _getPassengerCount() {
    let passengerCount;
    if ((this.travelType === 'Business' && !this.isVacations) ||
      (this.travelType === 'AirPass' && !this.isVacations)) {
      passengerCount = this.passengerCountBusiness.getAttribute("value").match(/\d+/)[0];
    } else {
      passengerCount = this.passengerCount.getAttribute("value").match(/\d+/)[0];
    }
    return passengerCount;
  }

  private _getPassengerDetails() {
    const travelType = this.dropdownTravelType?.querySelector("hp-booking-widget-dropdown")?.value;
    const travelerValue = this.dropdownBusinessTraveler?.querySelector("hp-booking-widget-dropdown")?.value;
    const selectedCompany = this.dropdownCompany?.querySelector("#companyName").value;
    const businessTravelerListbox = this.dropdownBusinessTraveler?.querySelector("hp-booking-widget-dropdown").shadowRoot?.querySelector(".adc-listbox");
    const filterCompany = this.businessCompanies?.filter(companyInfo => companyInfo?.name === selectedCompany);
    this.passengers = [];
    const pax : Passenger = {
      type : PassengerTypes.ADULT,
      count: this._getPassengerCount(),
      travelers : ["-1"]
    };

    if(travelerValue === TranslationDataController.translations['Guest']){
      this.passengers = [];
      pax.travelers = ['GUEST'];
    } else if(this.userLoggedInDetails && travelType === TranslationDataController.translations['Business'] && this.isAccountTravelManager && this._isAdvantageBusiness && businessTravelerListbox?.value) {
        this.passengers = [];
        let selectedTravelerIndex = this.formatCompanyTravelers(selectedCompany).findIndex(traveler => traveler === travelerValue);
        const selectedTravelerAccount = this.traveler && this.traveler !== 'GUEST' && travelerValue === this.getTravelerFromRequest() ? this.traveler : filterCompany[0]?.travelers[selectedTravelerIndex]?.uuid;

        pax.travelers = [selectedTravelerAccount];
    }
    else if(this.userLoggedInDetails && travelType === TranslationDataController.translations['Business'] && !this.isAccountTravelManager && this._isAdvantageBusiness) {

      const selectedTravelerAccount = filterCompany[0]?.travelers?.filter(traveler => {
        return traveler.isLoggedInUser;
      })
      pax.travelers = [selectedTravelerAccount?.[0]?.uuid];
    }

    this.passengers.push(pax);

    return this.passengers;
  }

  private resetAwardCheckbox() {
    this.awardCheckbox.removeAttribute("checked");
  }

  private _handleTravelTypeSelect() {
    const travelType = this.dropdownTravelType?.querySelector("hp-booking-widget-dropdown").value;

    // Reset values
    this.isAirPass = false;
    this.isPersonal = false;
    this.isBusiness = false;

    switch (travelType) {
      case TranslationDataController.translations['Business']:
        this.travelType = 'Business';
        this.isBusiness = true;
        this.isFlights = true;
        this.passengerCount.style.visibility = "hidden";
        this.passengerCountBusiness.style.visibility = "visible";
        this.passengerCountBusiness.style.position = "relative";

        if (this.businessCompanies?.length === 1) {
          this.isAccountTravelManager = this.businessCompanies[0]?.loggedInUserRole === "Account Travel Manager";
          this.company = this._isAdvantageBusiness ? this.businessCompanies[0]?.name : this.businessCompanies[0]?.companyName;
          this.thresholdIndicator = this.businessCompanies[0]?.thresholdIndicator;
          this.showThresholdNotification = (!this.thresholdIndicator) ? true : false;
        } else {
          this.shadowRoot?.getElementById("redeemMiles")?.setAttribute("disabled", "");
        }

        this.resetPassengerDropdown();
        this.resetAwardCheckbox();

        if (window.innerWidth < 800) {
          this.mobileAwardCheckbox.removeAttribute("checked");
        }
        break;

      case TranslationDataController.translations['AirPass']:
        this.travelType = 'AirPass';
        this.isAccountTravelManager = false;
        this.company = "";
        this.isAirPass = true;
        this.shadowRoot?.getElementById("redeemMiles")?.setAttribute("disabled", "");
        this.renderPassengers();
        break;

      case TranslationDataController.translations['Personal']:
        this.travelType = 'Personal';
        this.isAccountTravelManager = false;
        this.company = "";
        this.isPersonal = true;
        this.passengerCount.style.visibility = "visible";
        this.passengerCountBusiness.style.visibility = "hidden";
        this.passengerCountBusiness.style.position = "absolute";
        this.travelerValue = "";
        this.resetRedeemMiles();
        break;

      default:
        if (!travelType) {
            this.travelType = '';
            this.isBusiness = false;
            this.isAccountTravelManager = false;
            this.resetRedeemMiles();
            this.passengerCountBusiness?.removeAttribute("disabled");
          }
        break;
    }

    this.passengers = [];
  }

  private _handleCompanySelect() {
    const company = this.dropdownCompany.querySelector("hp-booking-widget-dropdown").value;
    if(window.innerWidth > 800) {
      const dropdownMenuPanel = this.dropdownCompany.querySelector("hp-booking-widget-dropdown").shadowRoot.querySelector(".aileron-dropdown__menu");
      dropdownMenuPanel.style.width = "auto";
      dropdownMenuPanel.style.maxWidth = "739px";
      dropdownMenuPanel.style.minWidth = "22%";
    }

    if (company) {
      const selectedCompanyData = this.businessCompanies?.find(businessTraveler => businessTraveler?.name === company);
      this.selectedAwardMiles = selectedCompanyData.awardEligibleMiles;
      this.showSelectedAwardMiles = this.selectedAwardMiles !== this.invalidAwardMiles;
      this.thresholdIndicator = selectedCompanyData?.thresholdIndicator;
      this.showThresholdNotification = (!this.thresholdIndicator) ? true : false;
      if(this.showThresholdNotification) {
        this.resetAwardCheckbox();
      }
      this.isAccountTravelManager = selectedCompanyData.loggedInUserRole === "Account Travel Manager";

      this.travelerValue = TranslationDataController.translations['SelectBusinessTraveler'];
      if( (this.dropdownBusinessTraveler !== null) && (this.company !== company) ) {
        const travelersDropdown = this.dropdownBusinessTraveler?.querySelector("hp-booking-widget-dropdown");
        this.travelerValue = travelersDropdown.triggerLabel;
        travelersDropdown.value = this.travelerValue;
        this.resetBusinessTravelDropdown();
      }
      this.company = company;
    } else {
      this.company = "";
      this.isAccountTravelManager = false;
      this.resetBusinessTravelDropdown();
    }
  }

  private resetBusinessTravelDropdown() {
    this.dropdownBusinessTraveler?.querySelector("hp-booking-widget-dropdown").shadowRoot?.querySelector(".adc-listbox").setAttribute('value', '');
  }

  private renderPassengers() {

    const passengerValue = this.passengerCountBusiness?.shadowRoot.querySelector("hp-booking-widget-dropdown-listbox").value;
    if (this.travelType === 'AirPass' && !this.isVacations) {
      this.isAirPass = true;
      this.passengerCount.style.visibility = "hidden";
      this.passengerCountBusiness.style.visibility = "visible";
      this.passengerCountBusiness.style.position = "relative";
      this.awardCheckbox?.removeAttribute("checked");
      this.mobileAwardCheckbox?.removeAttribute("checked");
      const airPassDetails = this.businessMemberInfo.programs.airpass.airpassProgramDetails[0];

      if (airPassDetails.isCompanionAllowed) {
        if (passengerValue !== TranslationDataController.translations['TwoPassenger']) {
          this.resetPassengerDropdown();
        }
        if (this.passenger_count > 0 && this.passenger_count <= 2) {
          this.passengerCountBusiness.shadowRoot.querySelector("hp-booking-widget-dropdown-listbox")!.value = TranslationDataController.translations[this.passengerValues[this.passenger_count - 1]]
        }
        return ["OnePassenger", "TwoPassenger"].map((pax) => {
          return TranslationDataController.translations[pax];
        });
      } else {
          this.resetPassengerDropdown();
          this.passengerCountBusiness?.setAttribute("disabled", "");
          return ["OnePassenger"].map((pax) => {
            return TranslationDataController.translations[pax];
          });
      }
    }

    if(this.travelType === 'Business' && !this.isVacations) {
      this.passengerCount.style.visibility = "hidden";
      this.passengerCountBusiness.style.visibility = "visible";
      this.passengerCountBusiness.style.position = "relative";
      return ["OnePassenger"].map((pax) => {
        return TranslationDataController.translations[pax];
      });
    }

    return this.passengerValues.map((pax) => {
      return TranslationDataController.translations[pax];
    });
  }

  private resetRedeemMiles() {
    this.shadowRoot?.getElementById("redeemMiles")?.removeAttribute("disabled");
  }

  private resetPassengerDropdown() {
    const paxCountValue = this.passengerCountBusiness?.shadowRoot.querySelector("hp-booking-widget-dropdown-listbox");
    paxCountValue.value = TranslationDataController.translations['OnePassenger'];
  }

  private renderTravelTypes() {
    const travelTypes = [
      TranslationDataController.translations['Personal']
    ]
    if (
      this._isAdvantageBusiness || this._isTriplink
    ) {
      travelTypes.push(TranslationDataController.translations['Business']);
    }
    if (this.businessMemberInfo?.programs?.airpass.airpassProgramDetails.length) {
      travelTypes.push(TranslationDataController.translations['AirPass']);
    }
    return travelTypes;
  }

  private formatCompanyTravelers(company) {
    const companyBusinessTravelers = this.businessCompanies?.find(businessTraveler => businessTraveler.name === company)?.travelers;
    const businessTravelerInfos = companyBusinessTravelers?.map(companyTraveler => {
      return `${companyTraveler.lastName  }, ${  companyTraveler.firstName  } (${  companyTraveler.maskedAccountNumber})`;
    });
    return businessTravelerInfos;
  }

  private onRecentSearchSelected(event) {
    const recentSearchDetails = event.detail;
    this.departureDate = this.formatDatePicker(recentSearchDetails.originTravelDate);
    this.returnDate = recentSearchDetails.destinationTravelDate ? this.formatDatePicker(recentSearchDetails.destinationTravelDate) : '';
    this.trip_type = recentSearchDetails.tripType;
    this.convertedTripType = this.convertTripType(this.trip_type); 

    if (this.convertedTripType === 'OneWay') {
      this.tripType.renderRoot.querySelector(".adc-listbox").renderRoot.querySelector("#adc-lb-0").ariaSelected = false;
      this.tripType.renderRoot.querySelector(".adc-listbox").renderRoot.querySelector("#adc-lb-1").ariaSelected = true;
      this.tripType.renderRoot.querySelector("hp-booking-widget-dropdown-listbox").value = TranslationDataController.translations['OneWay'];
    } else if (this.convertedTripType ===  'RoundTrip') {
      this.tripType.renderRoot.querySelector(".adc-listbox").renderRoot.querySelector("#adc-lb-1").ariaSelected = false;
      this.tripType.renderRoot.querySelector(".adc-listbox").renderRoot.querySelector("#adc-lb-0").ariaSelected = true;
      this.tripType.renderRoot.querySelector("hp-booking-widget-dropdown-listbox").value = TranslationDataController.translations['RoundTrip'];
    }

    setTimeout(() => {
      event.detail.inputObj.renderRoot.querySelector(".adc-text-input").focus();
    } , 10);

    this._setValuesFromRequest(recentSearchDetails.originAirport, recentSearchDetails.destinationAirport, true);
  }

  private convertTripType(tripType) {
    return tripType?.charAt(0).toUpperCase() + tripType?.slice(1);
  }

  private formatDatePicker(travelDate) {
    return this._normalizeDate(travelDate.monthOfYear + '/' + travelDate.dayOfMonth + '/' + travelDate.year);
  }

  get version(): string {
    return version;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "adc-booking-widget": BookingWidget;
  }
}
