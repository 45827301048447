/**
 * Roll up data used to return content
 * if input locale data does not exist in translation files
 */
 // eslint-disable-next-line @typescript-eslint/no-extraneous-class
 export class GlobalTranslations {
    /**
     * @ignore
     */
    public static DATA: any = {
      'en_US': {
        'OneWorld': 'oneworld link opens in a new window',
        'newWinText': ', Opens in a new window.',
        'newWinExtText': ', Opens another site in a new window that may not meet accessibility guidelines.'
      },
      'es': {
        'OneWorld': 'El enlace de oneworld abre en una nueva ventana',
        'newWinText': ', Abre en una nueva ventana.',
        'newWinExtText': ', Abre una nueva ventana de otro sitio web que podr\u00EDa no cumplir con las normas de accesibilidad.'
      },
      'fr': {
        'OneWorld': `Le lien oneworld s'ouvre dans une nouvelle fenêtre`,
        'newWinText': ', Ouvre dans une nouvelle fen\u00EAtre.',
        'newWinExtText': ', Ouvre un nouveau site dans une nouvelle fen\u00EAtre pouvant ne pas respecter les directives d’accessibilit\u00E9.'
      },
      'pt': {
        'OneWorld': 'O link da oneworld abre em uma nova janela',
        'newWinText': ', Abre em uma nova janela.',
        'newWinExtText': ', Abre um outro site numa nova janela que pode n\u00E3o cumprir as normas de acessibilidade.'
      }
    };
  }
  