import axios from 'axios';
import { Rollup } from '../../assets/languageRollup';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class TranslationDataController {

  static translations: {
    [key: string]: string;
  } = {};

  static getLocaleTranslations(locale, componentTranslations) {
    const updateLocale = this.transformLocaleFormat(locale);
    const rollupLocales = ['en_US', ...(Rollup.DATA.rollup[updateLocale] || []).reverse(), updateLocale];
    for(const code of rollupLocales) {
      TranslationDataController.translations = {...TranslationDataController.translations, ...componentTranslations.DATA[code] };
    }
  }

  private static transformLocaleFormat(s: string) {
    return s.replace('-', '_');
  }

  static getLocaleRollup(localeCode) {
    return Rollup.DATA.rollup[localeCode];
  }

  //retrive locale from cookie
  private static  _checkCookie(): string {
    let cookie = "en_US";
    const parts = document.cookie.split("; sessionLocale=");
    if (parts !== null && parts.length === 2) {
      const parsedLocale = parts.pop()?.split(";").shift();
      if (parsedLocale !== undefined) {
        cookie = this.transformLocaleFormat(parsedLocale.toString());
        }
    }
    return cookie;
  }

  private static _updateCookie(urlParamValue: string) {
    const parts = document.cookie.split("; sessionLocale=");

    if (parts !== null && parts.length === 2) {
      const formattedLocale = this.transformLocaleFormat(urlParamValue);
      document.cookie = `sessionLocale=${formattedLocale}`;
    }
  }

  // retrive locale from URL path
  static retrieveLocale(): string {
    let locale = "en_US";
    const currentPageURL = new URLSearchParams(window.location.search);
    const urlParamValue = Object.fromEntries(currentPageURL.entries())?.locale;
    const cookieValue = this._checkCookie();

    //if url param is not null
    if (urlParamValue !== null && urlParamValue !== undefined) {
      locale = this.transformLocaleFormat(urlParamValue);
      this._updateCookie(urlParamValue);
    } else if (cookieValue !== null && cookieValue !== undefined) {
      locale = this.transformLocaleFormat(cookieValue);
    }
    return locale;
  }

  static async getTranslationData(componentName:string, protocol:string): Promise<any>{
    const locale = this.retrieveLocale();
    const baseUrl = document.location.hostname === 'localhost' ? 'iqa2.qa.aa.com' : document.location.hostname;
    if(locale in Rollup.DATA.rollup){
      const locales = [locale, ...Rollup.DATA.rollup[locale], 'en_US'];

      for(const loc of locales) {
        try {
          const response = await axios.get(`${protocol}//${baseUrl}/pubcontent/${loc}/fragments/navigation/${componentName}/${componentName}.json`)
          return response;
        }
        catch(error) {
          continue;
        }
      }
    }
    else {
      try {
        const response = await axios.get(`${protocol}//${baseUrl}/pubcontent/${locale}/fragments/navigation/${componentName}/${componentName}.json`);
        return response;
      }
      catch(error) {
        return error;
      }
    }
  }
}
