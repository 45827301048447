export interface BookingDetails {
    loyaltyInfo?: LoyaltyInfo;
    passengers: Passenger[] | number;
    requestHeader: object;
    slices: Slice[];
    tripOptions: TripOptions;
    
}

export enum FareTypes{
    LOWEST,
    REFUNDABLE
}

export enum SearchTypes{
    AWARD = 'AWARD',
    REVENUE = 'REVENUE'
}

export enum PassengerTypes {
    ADULT = 'ADULT',
    CHILD = 'CHILD',
    SENIOR = 'SENIOR',
    YOUTH = 'YOUTH',
    INFANT_SEAT = 'INFANT_SEAT'  
}

export interface LoyaltyInfo {
    loyaltyId: string;
    bean: string;
    tripLink: boolean;
    airPass: boolean;
}

export interface Passenger {
    type: PassengerTypes; // adult, senior, youth, child, etc
    count: number;
    travelers: string[];
}

export interface Slice {
    allCarriers: boolean;
    cabin: string;
    departureDate: string;
    destination: string;
    destinationNearbyAirports: boolean;
    origin: string;
    originNearbyAirports: boolean;
}

export interface TripOptions {
    searchType: SearchTypes; // REVENUE,AWARD
    travelType: string;
    locale: string;
}

export interface BusinessProgram {
    smb?: AdvantageBusiness;
    tripLink: boolean;
}

export interface AdvantageBusiness {
    accountId: string; 
    companyName: string;
}

export const requestHeader = {
    clientId: "AAcom",
    referringPage: "HomePage-BookingWidget"
}

export interface MemberInformationRequest {
    firstName: string;
    lastName: string;
    advantageNumber: string;
}
